import React, { useState } from "react";
import "../../styles/thanks.css";

function ThankYou() {
  const [show, setshow] = useState(true);

  const disableThanks = () => {
    setshow(false);
  };

  return (
    <>
      {show === true ? (
        <div className="_thanks">
          <img
            className="_lota_thanks"
            src="./lota-thanks.svg"
            onClick={disableThanks}
          />
        </div>
      ) : null}
    </>
  );
}

export default ThankYou;
