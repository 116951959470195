import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import mainImg from "../../images/main-img.jpg";
// import mainImgGdm from "../../images/main-img-gdm.jpg";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container2 = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("/poster-opt2.jpg");
`;

// const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-white leading-snug -mt-24`}
  span {
    ${tw`inline-block mt-2`}
  }
  span {
    ${tw`inline-block mt-2 text-custom-red font-bold text-3xl xl:text-4xl sm:text-xl`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-gold-100 text-gray-100 hocus:bg-gold-200 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#about">About</NavLink>
      <NavLink href="#gallery">Gallery</NavLink>
      <NavLink href="#programme">Programme </NavLink>
      <NavLink href="#sponsors">Sponsors</NavLink>
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">
    //     Hire Us
    //   </PrimaryLink>
    // </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content id="about">
          <Heading>
            Saturday, 3rd Sep 2022
            <br />
            5pm - 9.45pm
            <br />
            <span>7 Taupo Quay, Whanganui 4500</span>
          </Heading>
          <PrimaryAction
            onClick={() => window.open("./LotA_programme_design.pdf", "_blank")}
          >
            Explore Programmes
          </PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
