import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import galleryData from "../../data/gallery2022.json";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center text-custom-red`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
  cssEase: "ease-in-out",
};

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center justify-center outline-none`;
// const ImageContainer = styled.div`
//   ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 flex items-center max-w-xs md:max-w-none`}
//   img {
//     ${tw``}
//   }
// `;
const ImageContainer = styled.div`
  ${tw`md:mx-6 lg:mx-6 p-8 flex items-center xs:w-full sm:w-11/12 flex justify-center items-center`}
  img {
    ${tw``}
  }
`;

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-center justify-center z-20 md:flex xs:hidden`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  //   console.log(galleryData);
  const testimonials = [
    {
      imageSrc: "./gallery/_MGL8865.jpg",
    },
    {
      imageSrc: "./gallery/_MGL8874.jpg",
    },
    {
      imageSrc: "./gallery/_MGL8875.jpg",
    },
  ];
  return (
    <Container id="gallery2022">
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Lanterns On The Awa 2022</HeadingTitle>
          {/* <HeadingDescription></HeadingDescription> */}
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider
            {...settings}
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
          >
            {galleryData.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <img
                    src={testimonial.imageSrc}
                    alt={testimonial.customerName}
                  />
                </ImageContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
        <HeadingInfoContainer>
          <HeadingDescription>
            <i>
              Thank you for all the images: Bev Sinclair, Milton Lister, Royce
              Robertson and Yvonne Malaquin.
            </i>
          </HeadingDescription>
        </HeadingInfoContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
